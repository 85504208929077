import { default as ai_45form_45builderIwBw4vJEp8Meta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as edit5uNtMINwb6Meta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index4ddOM17W7wMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexo5jEmhh09oMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as index5AU7xPrJpTMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as share5WjcFiDy12Meta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsFfvM9j13emMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissions2A3bsqWCy2Meta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as show2HbQR0gzzEMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestQu0D6K5WYsMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexEnPDrNSA9mMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homeBGIs5YQvjMMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/home.vue?macro=true";
import { default as indexOLtcyRKyr4Meta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/index.vue?macro=true";
import { default as loginQnY9cO73MpMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callbackQcXtXoHsQjMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as email17b4THO3poMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93AIcJt3zUOuMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingDyqjJtNwMoMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyKxGuXXtauEMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerZIDHs9ZSslMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokenskvrVpu9HGrMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as account5yx1OLLlUBMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as admin6Rd3tKzJsNMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingxUKg8ChO1tMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionsusel2nUNm2Meta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as indexDdhilWAexkMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordBp0ru3Vd3PMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profileJn1UpB3mixMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspaceyNlvmZ5bf3Meta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsEdy15JeeLJMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as error6gTd3fdwSbMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as success6ZiOL0KpJiMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93rKJHLBxIDiMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexJIeLjWayOnMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93IE1THGm1LmMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesosYFJTRzNPMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93N2wXByINMsMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionssWZKWGhfWfMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsBMZ96iV4pfMeta } from "/codebuild/output/src3041024094/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderIwBw4vJEp8Meta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: edit5uNtMINwb6Meta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: show2HbQR0gzzEMeta?.name,
    path: "/forms/:slug()/show",
    meta: show2HbQR0gzzEMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexo5jEmhh09oMeta?.redirect,
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: index5AU7xPrJpTMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: share5WjcFiDy12Meta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsFfvM9j13emMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissions2A3bsqWCy2Meta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestQu0D6K5WYsMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexEnPDrNSA9mMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homeBGIs5YQvjMMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginQnY9cO73MpMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: email17b4THO3poMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93AIcJt3zUOuMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingDyqjJtNwMoMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyKxGuXXtauEMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerZIDHs9ZSslMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsEdy15JeeLJMeta?.name,
    path: "/settings",
    meta: settingsEdy15JeeLJMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: account5yx1OLLlUBMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: admin6Rd3tKzJsNMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingxUKg8ChO1tMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsusel2nUNm2Meta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexDdhilWAexkMeta?.redirect,
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordBp0ru3Vd3PMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileJn1UpB3mixMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceyNlvmZ5bf3Meta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: error6gTd3fdwSbMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: success6ZiOL0KpJiMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesosYFJTRzNPMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionssWZKWGhfWfMeta || {},
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src3041024094/src/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]